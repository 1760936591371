import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { STRING } from "../config/string";
import ROUTE_URLS, { BASE_URL } from "../config/routes";

const Layout = () => {
  const [isCommingSoon, setIsCommingSoon] = useState(false);
  useEffect(() => {
    console.log("isCommingSoon state:", isCommingSoon);
  }, [isCommingSoon]);
  return (
    <div
      className="bg-black scrollbar-custom h-screen relative overflow-y-scroll"
      id="parent-scroll-bar"
    >
      {isCommingSoon && (
        <div id="cs_1" className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50"> {/* Overlay background */}
          <div id="cs_2" className="bg-gray-800 p-6 rounded-lg shadow-lg w-[300px] z-100000 text-center transition-all duration-700 ease-in-out transform scale-100 opacity-100">
            <button
              className="absolute top-2 right-2 text-white text-lg hover:text-gray-400" // Hover effect on close button
              onClick={() => setIsCommingSoon(false)} // Close modal on click
            >
              &#10005; {/* This renders the X icon */}
            </button>
            <h2 className="text-white text-xl mb-4">Coming Soon!</h2>
            <div className="relative pt-1">
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-700">
                <div
                  style={{ width: "90%" }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*<div
        className="hidden right-[-65px] sm:flex text-black lg:flex xl:flex 2xl:flex cursor-pointer w-[180px] flex-row-reverse items-center justify-center gap-[10px] h-[50px] top-[75%] fixed bg-white p-1 rotate-[-90deg] text-[18px] font-[700] z-[999]"
        id="live-chat"
      >
        <div onClick={() => setIsCommingSoon(true) }>{STRING.LIVE_CHAT}</div>
        <img
          className="rotate-[90deg]"
		  src={BASE_URL+"img/chat.svg"}
          alt="Live Chat"
		  onClick={() => setIsCommingSoon(true) }
        />
      </div>*/
	  }

      {/*
      <div className="flex sm:hidden w-16 h-16 cursor-pointer bg-white rounded-full fixed bottom-7 right-5 z-[999] justify-center items-center"
		onClick={() => setIsCommingSoon(true) }>
        <img src={BASE_URL+"img/chat.svg"} alt="" className="text-xl" onClick={() => setIsCommingSoon(true) } />
      </div>
	  */}
	  
      <Header setIsCommingSoon={setIsCommingSoon} />
	  
      <div className="mt-[90px] layoutContainer sm:mt-[143px] md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
        <Outlet context={{ setIsCommingSoon }} />
      </div>
      
	  <Footer setIsCommingSoon={setIsCommingSoon} />
    </div>
  );
};

export default Layout;
