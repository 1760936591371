export const STRING = {
  TECHNOLOGY: "Technology",
  PRICE: "Price",
  CONTACTS: "Contacts",
  USER_NUMBER: "(646) 435-3015",
  OPEN_AN_ACCOUNT: "Open an Account",
  NEXT: "NEXT",
  GENERATION: "GENERATION",
  BROKERAGE: "BROKERAGE",
  HERO_DESCRIPTION: "A minimalist, low-cost brokerage, and advanced infrastructure designed with active and institutional traders in mind",
  REQUEST_A_DEMO: "Request a Demo",
  OUR_TECHNOLOGY: 'Our <br/> Technology',
  CONNECT_TO_BLOOMBERG_EMSX: 'Connect to <br/> Bloomberg EMSX',
  EXECUTION_MANAGEMENT_SYSTEM_DEC: "Execution Management System Solutions that focus on end-to-end workflows and allow you to dynamically manage your positions, portfolios and trading risk",
  SIMPLIFY_YOUR_WORKFLOW: 'Simplify your workflow to trade  smarter and faster with REDI EMS',
  THE_BROKER_NEUTRAL_DEC: 'The broker-neutral, multi-asset execution management system that can give you a competitive edge.',
  GET_EFFICIENT_EXECUTION: 'Get efficient execution <br/> and real-time market <br/> data',
  DAS_SYSTEMS_PROVIDE_DES: 'DAS systems provide efficient execution and real-time market data solutions for your firm’s clients. Ideal for those traders that demand smarter execution services and streaming market data.',
  PRICEING: 'Pricing',
  PRICING_TAILORED_TOWARDS: "Pricing tailored towards each individual clients needs",
  WE_SPECIALIZE_IN_APP: "We specialize in DVP Accounts. Price as low as $0.0003/sh",
  PRICE_NUMBER: "> $0.0003/SH",
  FOOTER_TITLE: "Let’s discuss ways we can help increase your trading efficiency.<br/>Consult with our experts.",
  FOOTER_TITLE_mobile: "Let’s discuss ways we can help increase your trading efficiency.<br/>Consult with our experts.",
  FOOTER_DEC: "Call (646) 435-3015 or email support@opalsecurities.com for more information.",
  COMPANY_DISCLOSURES: "Company Disclosures",
  DISCLAIMER: "Disclaimer",
  FORM_CRS: "Form CRS",
  PRIVACY_NOTICE: "Privacy Notice",
  CUSTOMER_AGREEMENT: "Customer Agreement",
  ODD: 'ODD',
  FINRA_RULE: "FINRA Rule 2270",
  REPORTS: "606 Reports",
  SIPC: "SIPC",
  FINRA: "FINRA",
  ECN_SCHEDULE: "ECN Schedule",
  LIVE_CHAT: 'LIVE CHAT',
  CONTACT_US: 'Contact Us',
  CONNECT_ANY_PARTY: "Connect any 3rd party OMS",
  LOREM: "EMS offers a suite of integrated functionality with prime brokers and clearing firms to facilitate position management and reporting requirements, alongside an allocation’s module allowing for real-time confirmations. Customers will also have access via API or FIX to facilitate order staging with leading OMS providers."
};
