import { Route, Routes } from "react-router-dom";
import "./App.css";
import ROUTE_URLS, { BASE_URL } from "./config/routes";
import Layout from "./layout/layout";
import Home from "./pages/home";

function App() {
  return (
    <Routes>
      <Route path={ROUTE_URLS.HOME} element={<Layout />}>
        <Route path={ROUTE_URLS.HOME} element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
