import React from "react";
import { STRING } from "../../config/string";
import ROUTE_URLS, { BASE_URL } from "../../config/routes";

const Footer = ({setIsCommingSoon}) => {
  return (
    <div id="footer" className="w-full md:px-[19px] px-[19px] xl:px-1 2xl:px-1 py-[60px] lg:py-0 xl:py-0 2xl:py-0  lg:max-w-[1080px]  mb-[74px] flex flex-col m-auto">
      {/* logo start */}
      <img alt="logo" onClick={() => window.location.reload()} className="w-[200px] ml-[-10px] cursor-pointer" src={BASE_URL+"img/OpalLogo.png"} />
      {/* logo end */}

      <div className="grid mt-[30px] lg:mt-[58px] xl:mt-[58px] 2xl:mt-[58px] grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="lg:border-r md:border-r xl:border-r 2xl:border-r  md:border-[#1A1A1A] xl:border-[#1A1A1A] lg:pr-[85px] xl:pr-[85px] md:pr-[39px] 2xl:pr-[85px] 2xl:border-[#1A1A1A] lg:border-[#1A1A1A]">
          <h1
            className="text-primary_active_color leading-[39px] hidden lg:block xl:block 2xl:block text-[36px] mb-[17px] font-[700]"
            dangerouslySetInnerHTML={{ __html: STRING.FOOTER_TITLE }}
          />
          <h1
            className="text-primary_active_color leading-[39px] block lg:hidden xl:hidden 2xl:hidden text-[24px] md:text-[21px] mb-[17px] font-[700]"
            dangerouslySetInnerHTML={{ __html: STRING.FOOTER_TITLE_mobile }}
          />
          <span
            className="text-[16px] md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-primary_active_color  font-[500]"
            dangerouslySetInnerHTML={{ __html: STRING.FOOTER_DEC }}
          />
          <div className="lg:flex md:flex xl:flex 2xl:flex hidden mt-[25px] items-center">
		   {/*
            <button onClick={() => setIsCommingSoon(true)} className="relative btn4 text-primary_active_color mr-[25px] border-primary_active_color border rounded-full px-4 py-3 font-semibold">
              {STRING.CONTACT_US}
		   </button> */
		   }
		   {/*<div className="flex items-center gap-[11px]">
			 <img
                className="cursor-pointer"
                alt="logo not found"
                src={BASE_URL+"img/linkdin.svg"}
              />
              <img
                className="cursor-pointer"
                alt="logo not found"
                src={BASE_URL+"img/twiter.svg"}
              />
              <img
                className="cursor-pointer"
                alt="logo not found"
                src={BASE_URL+"img/facebook.svg"}
              />
              <img
                className="cursor-pointer"
                alt="logo not found"
                src={BASE_URL+"img/instagram.svg"}
			 />
            </div>*/}
          </div>
		  {/*
          <button onClick={() => setIsCommingSoon(true)} className="relative btn4 text-primary_active_color w-full mt-[30px] md:hidden lg:hidden xl:hidden 2xl:hidden border-primary_active_color border rounded-full px-4 py-3 font-semibold">
            {STRING.CONTACT_US}
          </button>*/}
        </div>

        <div className="lg:ml-[90px] xl:ml-[90px] 2xl:ml-[90px]">
          <div className="border-primary_color border-t-2 my-[30px]  hidden lg:hidden xl:hidden 2xl:hidden" />
          <div className="flex flex-col">
            <div className="border border-[#CDCDCD] my-[30px] lg:my-0  md:my-0 xl:my-0 2xl:my-0 lg:border-0 md:border-0 xl:border-0 2xl:border-0" />
            <div className="grid md:pl-[39px] grid-cols-2">
              <div className="text-white text-[14px] font-[400] flex flex-col gap-[12px]">
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/company-disclosures.pdf" target="_blank">{STRING.COMPANY_DISCLOSURES}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/disclaimer.pdf" target="_blank">{STRING.DISCLAIMER}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/form-crs.pdf" target="_blank">{STRING.FORM_CRS}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/privacy-notice.pdf" target="_blank">{STRING.PRIVACY_NOTICE}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/customer-agreement.pdf" target="_blank">{STRING.CUSTOMER_AGREEMENT}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/odd-disclosure.pdf" target="_blank">{STRING.ODD}</a>
                </span>
              </div>
              <div className="text-white text-[14px] font-[400] flex flex-col gap-[12px]">
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/finra-rule-2270-day-trading-disclosure.pdf" target="_blank">{STRING.FINRA_RULE}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://public.s3.com/rule606/opal/" target="_blank">{STRING.REPORTS}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/sipc/" target="_blank">{STRING.SIPC}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://www.finra.org/" target="_blank">{STRING.FINRA}</a>
                </span>
                <span>
                  <a className="hover:text-primary_active_color" href="https://opalsecurities.com/pdf/ecn-schedule.pdf" target="_blank">{STRING.ECN_SCHEDULE}</a>
                </span>
              </div>
            </div>
            <div className="flex md:pl-[39px] justify-center lg:justify-start xl:justify-start 2xl:justify-start">
              <img
                onClick={() => {
                  window.open('https://brokercheck.finra.org/firm/summary/8246', '_blank');
                }}
                className="w-[128px] mt-[38px]"
                src={BASE_URL+"img/banner.svg"}
                alt="banner not found"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
