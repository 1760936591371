import React from "react";

// import css and other
import { STRING } from "../../config/string";
import ScrollAnimation from "react-animate-on-scroll";

import ROUTE_URLS, { BASE_URL } from "../../config/routes";

const HeroSection = ({setIsCommingSoon}) => {
  return (
    <div className="flex flex-col md:flex-row lg:flex-row w-full  mx-auto lg:w-[1080px] md:px-[19px] sm:px-[19px] px-[19px]  xl:w-[1280px] 2xl:w-[1280px] 2xl:px-[103px] xl:px-[103px]">
      <div
        id="hero-content"
        className=" flex sm:px-[19px] 2xl:px-[0px] lg:px-[0px] flex-col justify-center flex-1 lg:pr-8 order-1 lg:order-1"
      >
        <ScrollAnimation animateIn="fadeInLeft">
          <h1 className="text-white font-[400] leading-[1] text-[3rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem]">
            {STRING.NEXT} <br /> {STRING.GENERATION}
          </h1>
          <h1 className="text-white font-[700] text-[3rem] md:text-[3rem] lg:text-[3.5rem] xl:text-[4rem]">
            {STRING.BROKERAGE}
          </h1>
          <span
            className="text-white mt-4 text-base md:text-lg lg:text-[21px] xl:text-[21px] 2xl:text-[21px]"
            dangerouslySetInnerHTML={{ __html: STRING.HERO_DESCRIPTION }}
          />
          <div className="flex flex-col sm:flex-row md:flex-row gap-4 items-center mt-6 lg:mt-[48px] xl:mt-[48px] 2xl:mt-[48px]">
		  {/*
			<button
              id="btn2"
              className="relative accountButton btn2 bg-white text-black text-sm md:text-base w-full md:w-auto rounded-full px-6 py-3 font-[700]"
			  onClick={() => setIsCommingSoon(true)}
            >
              {STRING.OPEN_AN_ACCOUNT}
            </button>
            <button
			  onClick={() => setIsCommingSoon(true)}
              id="btn3"
              className="relative btn3 accountButton text-white border-white border text-sm md:text-base w-full md:w-auto rounded-full px-6 py-3 font-[700]"
            >
              {STRING.REQUEST_A_DEMO}
            </button>
		  */}
          </div>
        </ScrollAnimation>
      </div>
      <div
        className="flex justify-center sm:justify-end sm:mt-4 items-center lg:items-start flex-1 mb-8 lg:mb-0 order-2 lg:order-2"
        id="hero-section-image"
      >
        <ScrollAnimation animateIn="fadeInUp">
          <img
            className="w-[110%]  md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl"
            src={BASE_URL+"img/hero_section.png"}
            alt="hero section"
          />
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default HeroSection;
