import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ROUTE_URLS, { BASE_URL } from "./config/routes";
import { BrowserRouter as Router } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Router basename={BASE_URL}>
      <App />
    </Router>
  </>
);


