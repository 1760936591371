import React from "react";


const Card = ({ cardDetails, isFirst, isLast }) => {

  return (
    <div>
      <div
        className={`flex gap-[20px] min-h-[235px] bg-light_gray_background pt-4 pb-8 px-6 flex-col 
        ${isFirst ? "rounded-tl-lg rounded-bl-lg" : ""} 
        ${isLast ? "rounded-br-lg rounded-tr-lg" : ""}`}
      >
        <img
          className="w-[28px] h-[31px]"
          src={cardDetails.path}
          alt="icon not found"
        />
        <span className="text-primary_active_color text-[21px] font-semibold">
          {cardDetails.title}
        </span>
        <span className="text-white lg:text-[18px] xl:text-[18px] text-[16px] 2xl:text-[18px]">
          {cardDetails.description}
        </span>
      </div>
    </div>
  );
};

export default Card;
