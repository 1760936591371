import React from "react";

// import css and other
import HeroSection from "../components/heroSection/HeroSection";
import Card from "../components/card/Card";
import { DATA } from "../config/data";
import OurTechnology from "../components/our_technology/OurTechnology";
import Price from "../components/price/Price";
import Carousel from "../components/carousel/Carousel";
import ScrollAnimation from "react-animate-on-scroll";
import { useOutletContext } from "react-router-dom";
import ROUTE_URLS, { BASE_URL } from "../config/routes";

const Home = () => {
  const { setIsCommingSoon } = useOutletContext();

  return (
    <div className="flex flex-col mx-auto">
      {/* hero section start */}
      <HeroSection setIsCommingSoon={setIsCommingSoon} />
      {/* hero section end */}

      {/* card section start */}
      <ScrollAnimation
        animateIn="fadeInUp"
        scrollableParentSelector="#parent-scroll-bar"
      >
        <div className="my-[34px] lg:my-14 w-full max-w-[1080px] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:px-[19px] px-[19px] xl:px-0 2xl:px-0 gap-1">
          {DATA.map((res, i) => {
            return (
              <div key={i}>
                <Card
                  cardDetails={res}
                  key={i}
                  isFirst={i === 0}
                  isLast={i === DATA.length - 1}
                />
              </div>
            );
          })}
        </div>
      </ScrollAnimation>
      {/* card section ends */}

      {/* our technology section start */}
      <OurTechnology />
      {/* our technology section end */}
      
	  <div className="w-full  mt-[20px] md:mt-[90px] lg:mt-[85px] px-[17px] lg:px-[100px] py-[75px] bg-[#1A1A1A] m-auto min-h-[300px] lg:min-h-[421px]">
        <Carousel />
      </div>

      <Price setIsCommingSoon={setIsCommingSoon}/>
    </div>
  );
};

export default Home;
