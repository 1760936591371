import React from "react";

// import css and other
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import { STRING } from "../../config/string";
import ROUTE_URLS, { BASE_URL } from "../../config/routes";

const NextArrow = ({ onClick }) => {
  return (
    <div className="custom-arrow next-arrow text-white p-2 rounded-full cursor-pointer" onClick={onClick}>
      <img src={BASE_URL+"img/rightArrow.svg"} alt="right icon not" />
    </div>
  )
}

const PrevArrow = ({ onClick }) => {
  return (
    <div className="custom-arrow prev-arrow text-white p-2 rounded-full cursor-pointer" onClick={onClick}>
      <img src={BASE_URL+"img/leftArrow.svg"} alt="left icon not" />
    </div>
  )
}

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="carosal-container max-w-[1280px] m-auto py-16 pt-0 md:py-8 lg:py-12">
      <ScrollAnimation
        animateIn="fadeInLeft"
        scrollableParentSelector="#parent-scroll-bar"
      >
        <div className="mb-8 md:mb-12 lg:mb-16">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-4 md:mb-6 lg:mb-8">
            {STRING.CONNECT_ANY_PARTY}
          </h1>
          <p className="text-sm md:text-base lg:text-lg text-primary_color">
            {STRING.LOREM}
          </p>
        </div>
      </ScrollAnimation>

      <Slider {...settings}>
        {[1, 2, 3].map((res, i) => {
          return (
            <div key={i} className="px-2">
              <div className="grid justify-center items-center grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                <img
                  className="object-contain w-[97px] lg:w-auto md:w-auto xl:w-auto 2xl:w-auto"
                  src={BASE_URL+"img/dastrader.svg"}
                  alt="DAS Trader"
                />
                <img
                  className="object-contain w-[97px] lg:w-auto md:w-auto xl:w-auto 2xl:w-auto"
                  src={BASE_URL+"img/bloomberg.svg"}
                  alt="Bloomberg"
                />
                <img
                  className="object-contain w-[97px] lg:w-auto md:w-auto xl:w-auto 2xl:w-auto"
                  src={BASE_URL+"img/redi_logo.svg"}
                  alt="REDI"
                />
              </div>
            </div>
          )
        })}

      </Slider>
    </div>
  );
};

export default Carousel;
