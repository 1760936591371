import React, { useEffect, useRef, useState } from 'react';

// import css and other
import Odometer from 'odometer';
import 'odometer/themes/odometer-theme-car.css';
import ScrollAnimation from "react-animate-on-scroll";
import { STRING } from "../../config/string";
// import BgArea from "./img/price.png";
import ROUTE_URLS, { BASE_URL } from "../../config/routes";

const Price = ({setIsCommingSoon}) => {
  const [isVisible, setIsVisible] = useState(false);
  const numberRef = useRef(null);
  const resNumberRef = useRef(null);

  const onScreen = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onScreen, { threshold: 0.5 });

    // Store refs in variables for cleanup
    const numberRefCurrent = numberRef.current;
    const resNumberRefCurrent = resNumberRef.current;

    // Observe elements
    if (numberRefCurrent) observer.observe(numberRefCurrent);
    if (resNumberRefCurrent) observer.observe(resNumberRefCurrent);

    // Cleanup observer on unmount
    return () => {
      if (numberRefCurrent) observer.unobserve(numberRefCurrent);
      if (resNumberRefCurrent) observer.unobserve(resNumberRefCurrent);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      if (numberRef.current) {
        const odometerDesktop = new Odometer({
          el: numberRef.current,
          value: 9,
          format: 'd',
          duration: 1000,
        });
        odometerDesktop.update(3);
      }
      if (resNumberRef.current) {
        const odometerMobile = new Odometer({
          el: resNumberRef.current,
          value: 9,
          format: 'd',
          duration: 1000
        });
        odometerMobile.update(3);
      }
    }
  }, [isVisible]);

  return (
    <div id='price' className="flex pt-[55px] sm:px-[19px] md:px-[19px] lg:mb-[82px] xl:mb-[82px] 2xl:mb-[82px] flex-col w-full md:max-w-[100%] lg:max-w-[1080px] xl:w-[1080px] 2xl:w-[1080px] m-auto px-1">
      <div
        className="price-Background w-full sm:block hidden md:block lg:block xl:block 2xl:block relative h-[523px]"

      >
        <ScrollAnimation
          animateIn="fadeInLeft"
          scrollableParentSelector="#parent-scroll-bar"
        >
          <h1
            className="hidden md:block sm:block lg:block xl:block 2xl:block text-primary_active_color text-[36px]"
            dangerouslySetInnerHTML={{ __html: STRING.PRICEING }}
          />
        </ScrollAnimation>
        <div>
          <ScrollAnimation
            animateIn="fadeInRight"
            scrollableParentSelector="#parent-scroll-bar"
          >
            <h1
              className="lg:text-[36px] sm:mt-[30px] md:text-[28px] xl:text-[34px] 2xl:text-[36px] sm:text-[20px] font-semibold mb-6 ml-[24rem] sm:leading-[25px] lg:leading-[38px] md:leading-[30px] md:mt-3 xl:leading-[38px] 2xl:leading-[38px] text-white"
              dangerouslySetInnerHTML={{
                __html: STRING.PRICING_TAILORED_TOWARDS,
              }}
            />
          </ScrollAnimation>
          <h6 className="lg:text-[18px] md:text-[16px] md:ml-[24rem] xl:text-[18px] 2xl:text-[18px] sm:text-[13px] sm:ml-[24rem] ml-[26rem] text-white">
            {STRING.WE_SPECIALIZE_IN_APP}
          </h6>
        </div>

        <div className="flex sm:mt-[3rem] lg:mt-0 md:mt-0 xl:mt-0 2xl:mt-0 flex-col md:gap-[10px] items-center">
          <div className='flex justify-center md:mt-[30px] items-center'>
            <h1 className="text-white font-bold text-[130px] sm:text-[65px] md:text-[40px] lg:text-[40px] xl:text-[120px]">
              {`> $0.000`}
            </h1>
            <span ref={numberRef} className="text-white font-bold text-[130px] md:text-[40px] sm:text-[65px] lg:text-[40px] xl:text-[120px] odometer">
              9
            </span>
            <h1 className='text-white font-bold text-[130px] md:text-[40px] sm:text-[65px] lg:text-[40px] xl:text-[120px]'>
              {`/SH`}
            </h1>
          </div>
          <ScrollAnimation
            animateIn="fadeInUp"
            scrollableParentSelector="#parent-scroll-bar"
          >
            <div className="flex gap-3 items-center sm:mt-[31px] lg:mt-[68px] xl:mt-[0px] 2xl:mt-[0px]">
			{/*
              <button onClick={() => setIsCommingSoon(true)} className="relative btn2 bg-white rounded-full px-4 py-3 font-bold">
                {STRING.OPEN_AN_ACCOUNT}
              </button>
              <button onClick={() => setIsCommingSoon(true)} className="relative btn3 text-white border-white border rounded-full px-4 py-3 font-semibold">
                {STRING.REQUEST_A_DEMO}
              </button>
			*/}
            </div>
          </ScrollAnimation>

        </div>
      </div>

      <div className="w-full px-[19px] sm:hidden md:hidden flex gap-[20px] flex-col justify-center lg:hidden xl:hidden 2xl:hidden relative">
        <img
          alt='mobile price not found∂'
          className="w-full"
          src={BASE_URL+"img/mobile_price.png"}
        />
        <h1
          className="text-primary_active_color absolute top-[0] left-[28px] text-[36px]"
          dangerouslySetInnerHTML={{ __html: STRING.PRICEING }}
        />
        <div className="flex flex-col items-center gap-[20px] priceContainer pr-[43px] pl-[19px] bottom-[25px] priceContainer absolute">
          <ScrollAnimation
            animateIn="fadeInRight"
            scrollableParentSelector="#parent-scroll-bar"
          >
            <h1
              className="text-white font-[700] priceText text-[24px]"
              dangerouslySetInnerHTML={{
                __html: STRING.PRICING_TAILORED_TOWARDS,
              }}
            />
          </ScrollAnimation>
          <h6 className="text-white">{STRING.WE_SPECIALIZE_IN_APP}</h6>
          <div className='flex items-center'>
            <h1 className="text-white font-bold text-[38px]">
              {`> $0.000`}
            </h1>
            <span ref={resNumberRef} className="text-white font-bold text-[38px] odometer">
              9
            </span>
            <h1 className='text-white font-bold text-[38px]'>
              {`/SH`}
            </h1>
          </div>
          <ScrollAnimation
            animateIn="fadeInUp"
            scrollableParentSelector="#parent-scroll-bar"
            className='w-full'
          >
            <div className="flex flex-col w-full gap-3 items-center sm:mt-[31px] lg:mt-[68px] xl:mt-[68px] 2xl:mt-[68px]">
			{/*
              <button className="relative btn2 bg-white rounded-full w-full px-4 py-3 font-bold"
			    onClick={() => setIsCommingSoon(true)}>
                {STRING.OPEN_AN_ACCOUNT}
              </button>
              <button onClick={() => setIsCommingSoon(true)} className="relative btn3 text-white border-white border w-full rounded-full px-4 py-3 font-semibold">
                {STRING.REQUEST_A_DEMO}
              </button>
			*/}
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default Price;
