import ROUTE_URLS, { BASE_URL } from "./routes";

export const DATA = [
  {
    path: BASE_URL+"img/smart_execution.svg",
    title: 'Smart Execution',
    description: 'Customized routes through multiple agency broker dealers'

  },
  {
    path: BASE_URL+"img/treding_down.svg",
    title: 'Low cost',
    description: 'Designed with institutional/active traders in mind'

  },
  {
    path: BASE_URL+"img/sliders_icon.svg",
    title: 'Customized analytics',
    description: 'Tailored to customer needs'

  },
  {
    path: BASE_URL+"img/headphone_icon.svg",
    title: 'Customer Service',
    description: '4:00am - 8:00pm EST'

  },
]
